import * as React from "react";
import { Link } from "gatsby";

import Bridge from "../../components/Bridge.js";
import Ch from "../../components/Chord.js";
import Chorus from "../../components/Chorus.js";
import Columns from "../../components/Columns.js";
import { Helmet } from "react-helmet";
import Layout from "../../components/Layout";
import Verse from "../../components/Verse.js";

export default function Younger() {
  return (
    <Layout>
      <Helmet title="Younger | Seinabo Sey" />

      <h1>Younger</h1>

      <h2>Seinabo Sey</h2>

      <h4>Key: G</h4>

      <h4>Tempo: 92</h4>

      <Link to="/">List</Link>

      <Columns columns="2">
        <Verse>
          <p>
            <b>D Em Bm A G Bm D G</b>
          </p>
        </Verse>
        <Verse>
          <p>
            There's a <Ch>D</Ch>conclusion to my illusion
          </p>
          <p>
            I <Ch>Em</Ch>assure you this
          </p>
          <p>
            There's no <Ch>Bm</Ch>end to this confusion
          </p>
          <p>
            If you let it <Ch>A</Ch>wish
          </p>
          <p>
            You <Ch>G</Ch>well, Soul to sell
          </p>
          <p>
            Highest <Ch>Asus</Ch>bidders, can't you tell what you're <Ch>G</Ch>
            getting?
          </p>
          <p>
            <b>A Bm D/A</b>
          </p>
        </Verse>
        <Verse>
          <p>
            There is a <Ch>D</Ch>light to all this darkness
          </p>
          <p>
            I will tell you <Ch>Em</Ch>this
          </p>
          <p>
            There's <Ch>Bm</Ch>redemption in you asking them just <Ch>A</Ch>why
            it is
          </p>
          <p>
            Some answers are <Ch>G</Ch>better left unspoken when you{" "}
            <Ch>Asus</Ch>
            know you ain't getting any
          </p>
        </Verse>
        <Chorus>
          <p>
            <Ch>G</Ch>Younger, younger, <Ch>A</Ch>younger
          </p>
          <p>
            Are<Ch>Bm</Ch> you?<Ch>D</Ch>
          </p>
          <p>
            <Ch>A</Ch>Younger, younger, <Ch>D</Ch>younger
          </p>
          <p>
            Are <Ch>G</Ch>you?
          </p>
          <p>You ain't getting any</p>
          <p>
            <Ch>G</Ch>Younger, younger, <Ch>A</Ch>younger
          </p>
          <p>
            Are<Ch>Bm</Ch> you?<Ch>D</Ch>
          </p>
          <p>
            <Ch>Em</Ch>Younger, younger, <Ch>Bm</Ch>younger
          </p>
          <p>
            Are <Ch>A</Ch>you?
          </p>
        </Chorus>
        <Verse>
          <p>
            Why we <Ch>D</Ch>fight to get on loving I've been <Ch>Em</Ch>
            wondering
          </p>
          <p>
            How your <Ch>Bm</Ch>mind will leave you hanging your heart{" "}
            <Ch>A</Ch>lingering
          </p>
          <p>
            Stay <Ch>G</Ch>lost
          </p>
          <p>
            Then found by <Ch>Asus</Ch>whomever stays around, <Ch>G</Ch>
            forgetting
          </p>
          <p>
            <b>A Bm D/A</b>
          </p>
        </Verse>
        <Verse>
          <p>
            There is a <Ch>D</Ch>way to be yourself, I <Ch>Em</Ch>assure you
            this
          </p>
          <p>
            There's a <Ch>Bm</Ch>way to catch your dreams without <Ch>A</Ch>
            falling asleep
          </p>
          <p>
            You might as well <Ch>G</Ch>get it while you can, babe
          </p>
          <p>
            'cause <Ch>Asus</Ch>you know you ain't getting any
          </p>
        </Verse>
        <Chorus>
          <p>
            <Ch>G</Ch>Younger, younger, <Ch>A</Ch>younger
          </p>
          <p>
            Are<Ch>Bm</Ch> you?<Ch>D</Ch>
          </p>
          <p>
            <Ch>A</Ch>Younger, younger, <Ch>D</Ch>younger
          </p>
          <p>
            Are <Ch>G</Ch>you?
          </p>
          <p>You ain't getting any</p>
          <p>
            <Ch>G</Ch>Younger, younger, <Ch>A</Ch>younger
          </p>
          <p>
            Are<Ch>Bm</Ch> you?<Ch>D</Ch>
          </p>
          <p>
            <Ch>Em</Ch>Younger, younger, <Ch>Bm</Ch>younger
          </p>
          <p>
            Are <Ch>A</Ch>you?
          </p>
        </Chorus>
        <Bridge>
          <p>
            <b>C Bm Em A</b>
            <b>C Bm Em A</b>
            <b>C Bm Em A</b>
            <b>C Bm Em D/A</b>
          </p>
        </Bridge>
        <Verse>
          <p>
            There is a <Ch>D</Ch>light to all this darkness if only <Ch>Em</Ch>
            we
          </p>
          <p>
            <Ch>Bm</Ch>Fight against them telling us how we <Ch>A</Ch>should be
          </p>
          <p>
            I <Ch>G</Ch>refuse to have you break me
          </p>
          <p>
            When you <Ch>Asus</Ch>know you ain't getting any
          </p>
        </Verse>
        <Chorus>
          <p>
            <Ch>G</Ch>Younger, younger, <Ch>A</Ch>younger
          </p>
          <p>
            Are<Ch>Bm</Ch> you?<Ch>D</Ch>
          </p>
          <p>
            <Ch>A</Ch>Younger, younger, <Ch>D</Ch>younger
          </p>
          <p>
            Are <Ch>G</Ch>you?
          </p>
          <p>You ain't getting any</p>
          <p>
            <Ch>G</Ch>Younger, younger, <Ch>A</Ch>younger
          </p>
          <p>
            Are<Ch>Bm</Ch> you?<Ch>D</Ch>
          </p>
          <p>
            <Ch>Em</Ch>Younger, younger, <Ch>Bm</Ch>younger
          </p>
          <p>
            Are <Ch>A</Ch>you?<Ch>F#m</Ch>
          </p>
        </Chorus>
        <Chorus>
          <p>
            <Ch>G</Ch>Younger, younger, <Ch>A</Ch>younger
          </p>
          <p>
            Are<Ch>Bm</Ch> you?<Ch>D</Ch>
          </p>
          <p>
            <Ch>A</Ch>Younger, younger, <Ch>D</Ch>younger
          </p>
          <p>
            Are <Ch>G</Ch>you?
          </p>
          <p>You ain't getting any</p>
          <p>
            <Ch>G</Ch>Younger, younger, <Ch>A</Ch>younger
          </p>
          <p>
            Are<Ch>Bm</Ch> you?<Ch>D</Ch>
          </p>
          <p>
            <Ch>Em</Ch>Younger, younger, <Ch>Bm</Ch>younger
          </p>
          <p>
            Are <Ch>A</Ch>you?<Ch>F#m</Ch>
          </p>
        </Chorus>
      </Columns>
    </Layout>
  );
}
